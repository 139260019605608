import { useEffect, useState } from "react";
import { Navigate } from 'react-router-dom';
import apiUrlBase from '../config/apiUrlBase'

export default function CharacterSingle() {
  
  const [rand, setRand] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title =  "Random | Hanzibase" ;
  });

  const fetchItem = async (signal , apiUrl = `${apiUrlBase}${window.location.pathname}`) =>  {
    // console.log("load");
    // console.log(apiUrl, {signal});
    try {
        const req = await fetch(apiUrl, {
            method: "GET",
            signal: signal,
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }})
            .then(async (response) => {
                
                //throw errors if issues
                if (response.status === 500) {
                    // console.log("500");
                }
                else if(response.status === 404) {
                    // console.log("404");
                }
                else if(response.status === 419) {
                    // console.log("419");
                }
                else if (response.status !== 400) {
                    // console.log(response.status);
                }

                const data = await response.json();
                setRand(data);
                
                if (!data.category_id) {
                    setLoading(false);
                } 
        })
    }
    catch(error) {

        // console.log("OKAY I ERROR AND LOAD IS "+loading)
        // console.log("Error ", error);
    }
}

useEffect(() => {
    const controller = new AbortController();
    fetchItem(controller.signal);
  
    return () => {
      controller.abort(); 
    };
  }, []);



  

  return (
    loading? <></> : <Navigate to={"/character/" + rand.character_object.char} />
  );
}