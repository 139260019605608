import { useEffect } from "react";
import Chars from "../Chars";
import { useState } from "react";
import DEV_MODE from "../../config/devMode";

export default function RadicalSearchCharsWrapper(props) {
  
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (props.isRadicalSearch) {
            setSearchTerm(
                decodeURI(window.location.pathname).replace("/radical-search/", "")
              );
        }
        else if (props.isSearch) {
          setSearchTerm(
            decodeURI(window.location.pathname).replace("/search/", "")
          );
        }
        if (DEV_MODE) {console.log('search term', searchTerm)}
      }, [props.isRadicalSearch, props.isSearch, searchTerm]);

    useEffect(() => {
        document.title =  `Characters with radical '${searchTerm}' | Hanzibase` ;
      });

 
      return (
        <Chars {...props}></Chars>
      );
}