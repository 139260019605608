import { useEffect, useState } from "react";

export default function Landing() {

    // search message visibility
    const [searchMsg, setSearchMsg] = useState(true);
    const [searchMsgStyle, setSearchMsgStyle] = useState({'display': 'block'});

    // search state / handle function
    const [search, setSearch] = useState("");
    const handleSearchChange = (e) => {
      setSearch(e.target.value);
    }

    useEffect(() => {
      if (search.length > 0) {
        setSearchMsg(false);
      }
      else {
        setSearchMsg(true);
      }
    }, [search])

    useEffect(() => {
      if(searchMsg) {
        setSearchMsgStyle({'display': 'block'});
      } 
      else {
        setSearchMsgStyle({'display': 'none'});
      }
    }, [searchMsg])

    return (
      <>
        <div className="landing-area">
          <div className="landing-area-content">
              <h1>HanziBase</h1>
              <p>Free resource for learning Chinese characters</p>
              <div className="search-container">
                  <form action={`/search/${search}`} method="GET">
                      <input onChange={(e) => handleSearchChange(e)} id="search" type="text" placeholder="Jia, 家, home..." autoComplete="off" maxLength={32} required></input>
                      <label style={{'display': 'none'}} htmlFor="search">Search field</label>
                      <button aria-label="Search Button" type="submit"><i className="fa fa-search"></i></button>
                  </form>
                  <div id="searchMsg" style={searchMsgStyle} className="search-msg">Search using Pinyin, unicode Chinese characters or translations</div>
              </div>       
          </div>
        </div>
      </>
    );
  }