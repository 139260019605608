import React from 'react'
import { Link } from 'react-router-dom';

export default function CharacterLink(props) {

    // console.log('I AM A new RESULT', props)

    let translations = "";

    if(props.translations) {
        let translationsArr = props.translations.match(/[a-zA-Z0-9]+/gi);
        
        // remove any duplicates
        translationsArr = [...new Set(translationsArr)];

        let letterCount = 0;
        let i = 0;

        do {
            if(translationsArr[i]){
                let comma = (translations === "") ? "" : ", ";
                translations = translations.concat(comma + translationsArr[i]); 
                letterCount = letterCount + translationsArr[i].length;
            }
            i++;
        }
        while(letterCount < 10 && i < translationsArr.length);
    }


    const isRadical = props.radical == props.char;

    // adds little glow to background if specifically searched for these chars
    const classNameString = props.currentSearchHanzi && props.currentSearchHanzi.indexOf(props.char) == -1 ? `character_link` : `character_link currentSearchHanzi`;

    return (
        
        <Link  ref={props.resultsLength - 1 == (props.currentCharIndex) ? props.lastCharacterRef : null} 
            to={`/character/${props.char}` } 

            className={classNameString}>
        {/* {{-- Top details, radical and trad/simp --}} */}
        <div className={"details top-details " + (isRadical ? "active" : "")}> 
            
            {isRadical ? (props.hasSimplified ? <p>Traditional Radical</p> : props.hasTraditional ? <p>Simplified Radical</p> : <p>Radical</p>) :  <p>{props.radical}</p> }
            {!isRadical && (props.hasSimplified ? "Traditional" : props.hasTraditional ? "Simplified" : null)}
        </div>

        <h2 className="character">{props.char}</h2>

        {/* Pinyin */}
        <p className="pinyin">
            { props.pinyin }
        </p>
        
        {/* Translations */}
        <h3>
            {translations}
        </h3>
        


        <div className="details bottom-details">
            {props.heisig_keyword ? <>
                
                <div>{`${props.heisig_keyword} (${props.heisig_number})`}</div>
                </> : null}
        </div>
        
        </Link> 
    )

}
