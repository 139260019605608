import { useEffect, useRef, useState } from "react";
import HanziWriter from "hanzi-writer";
import $ from "jquery";
import Landing from "./partials/Landing";
import Footer from "./partials/Footer";
import Navbar from "./partials/Navbar";
import Updates from "./partials/Updates";
import { Link } from "react-router-dom";
import apiUrlBase from "../config/apiUrlBase";
import DEV_MODE from "../config/devMode";

function toggleSearchMsgVisablityOn() {
  document.getElementById("searchMsg").className = "searchMsg visable";
}
function toggleSearchMsgVisablityOff() {
  document.getElementById("searchMsg").className = "searchMsg";
}

var chars = [
  "的", // 1
  "一", // 2
  "是", // 3
  "不", // 4
  "了", // 5
  "人", // 6
  "我", // 7
  "在", // 8
  "有", // 9
  "他", // 10

  "这", // 11
  "为", // 12
  "之", // 13
  "大", // 14
  "来", // 15
  "以", // 16
  "个", // 17
  "中", // 18
  "上", // 19
  "们", // 20

  "到", // 21
  "说", // 22
  "国", // 23
  "和", // 24
  "地", // 25
  "也", // 26
  "子", // 27
  "时", // 28
  "道", // 29
  "出", // 30
];

const arrRandom = function (arr) {
  return arr[Math.floor(Math.random() * arr.length)];
};

function initialiseWriter(size) {
  return HanziWriter.create("writer", "好", {
    width: size,
    height: size,
    padding: 0,
    showCharacter: false,
    strokeAnimationSpeed: 1, // 5x normal speed
    delayBetweenStrokes: 1000, // milliseconds
    strokeColor: "#c82929", // red
    delayBetweenLoops: 1000,
    showOutline: false,
  });
}

export default function Home(props) {
  const [charCount, setCharCount] = useState(0);
  const [currentChar, setCurrentChar] = useState("\u6211");
  const [writer, setWriter] = useState(null);
  const hanziWriterInitialised = useRef(false);

   // only reset to top on first load
   const [initToTop, setInitToTop] = useState(false);
   if (!initToTop) {
     setInitToTop(true)
     props.toggleToTop() // reset page to top
   }

   const fetchItem = async (signal , apiUrl = `${apiUrlBase}/character/count`) =>  {
    // console.log("load");
    // console.log(apiUrl, {signal});
    try {
        await fetch(apiUrl, {
            method: "GET",
            signal: signal,
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }})
            .then(async (response) => {
                const data = await response.json();
                setCharCount(data);

        })
    }
    catch(error) {
    }
}

useEffect(() => {
  const controller = new AbortController();
  fetchItem(controller.signal);

  return () => {
    controller.abort(); 
  };
}, []);

  useEffect(() => {
    document.title =  "Hanzibase" ;
  });

  useEffect(() => {
    hanziWriterInitialised.current = true;
  }, []);

  useEffect(() => {
    if (writer !== null) {
      writer.animateCharacter({
        onComplete: function () {
          setTimeout(function () {
            getNewChar(writer);
          }, 5000);
        },
      });
    } else {
      // console.log("runnign once");
      let size = $("#writer").width();
      setWriter(initialiseWriter(size === undefined ? 30 : size));
    }

    function getNewChar(writer) {
      // search the chars obj for current char
      var currentCharInCharsObj = chars.find((x) => x === currentChar);
      var newChar;

      while (true) {
        newChar = arrRandom(chars);
        if (DEV_MODE) { console.log(newChar); }

        if (newChar != currentCharInCharsObj) {
          break;
        }
      }

      setCurrentChar(newChar);
      writer.setCharacter(currentChar);
    }
  }, [currentChar, writer, hanziWriterInitialised]);

  return (
    <div className='homepage'>
    <Navbar isHomepage={true} toggleTheme={props.toggleTheme}/>
    <Landing />
    <div className="main">
      <main>
        <div className="home-section">
          <div className="nihao">
            <h2 className="stat-title">
              <span className="red">{charCount}</span> Characters on HanziBase
            </h2>
            <div id="writer"></div>
          </div>
          <div className="title-section">
            <h2 className="stat-title">
              <span className="red">{charCount}</span> Characters on HanziBase
            </h2>
            <p>
              HanziBase is a free resource for learning Chinese characters, both
              simplifed and traditional. You can search by character, pinyin,
              translation or Heisig keyword (if you are using{" "}
              <em>Remembering the Hanzi</em>) to find a character's details.
            </p>
          </div>
        </div>

        <div className="home-section">
          <div className="title-section">
            <h2 className="how-it-works-title red">How It Works</h2>
            <p>
              When you search for a character by typing in at least one unicode
              Chinese Character into the search, Hanzibase will attempt to find
              it within the local database. If a character is not within the
              website's database, it will attempt to find information about it
              through the <a href="http://ccdb.hemiola.com/">CCDB API</a> as
              well as the{" "}
              <a href="https://glosbe.com/a-api">Glosbe Translation API</a>.
              This content is then added to the local database for future use.
            </p>
          </div>
          <i className="fas fa-cloud-download-alt feature-icon-large"></i>
        </div>

        <div className="home-section reverse-section">
          <div className="title-section">
            <h2 className="how-it-works-title red">Easy to Use</h2>
            <p>
              Hanzibase has filters you can apply when searching to make finding
              the character you're looking for easier. Each character's radical
              will be in the top left corner, and pinyin, translations and
              heisig data is underneath the character.
            </p>
          </div>

          {/* {{-- Example character Link --}} */}
          <div className="character_link_example">
            <div className="details top-details ">Radical</div>
            <h2 className="character">字</h2>
            <p className="pinyin">pinyin</p>
            <h3>translations</h3>

            <div className="details bottom-details">
              <div>heisig keyword (heisig number)</div>
            </div>
          </div>
        </div>

        <h2 className="sub-features-title red">Features</h2>

        <div className="sub-features">
          <h2 className="feature-title">Translations</h2>
          <h2 className="feature-title">Stroke Order</h2>
          <h2 className="feature-title">Radicals</h2>

          <i className="fas fa-language feature-icon"></i>
          <i className="fas fa-pen-fancy feature-icon"></i>
          <i className="fas fa-star feature-icon"></i>

          <p className="feature-text">
            Some characters may have multiple translations, and they will all be
            listed on the character page. Heisig keywords are listed as seperate
            from API generated translations.
          </p>

          <p className="feature-text">
            The amazing{" "}
            <a href="https://hanziwriter.org/">
              HanziWriter javascript library
            </a>{" "}
            is used to render all characters that support it, providing the
            stroke order of the character. Characters will also have information
            about the total number of strokes, as well as the radical.
          </p>

          <p className="feature-text">
            Every character either has a radical or is a radical. Some radicals
            will have both a simplifed and traditional variant, and the radicals
            conterpart will be displayed as a link on the character page if this
            is the case. You can filter in the search to show only radicals.
          </p>
        </div>

        <div className="sub-features-small">
          <div className="feature">
            <h2>Translations</h2>
            <i className="fas fa-language"></i>
            <p>
              Some characters may have multiple translations, and they will all
              be listed on the character page. Heisig keywords are listed as
              seperate from API generated translations.
            </p>
          </div>
          <div className="feature">
            <h2>Stroke Order</h2>
            <i className="fas fa-pen-fancy"></i>
            <p>
              The amazing{" "}
              <a href="https://hanziwriter.org/">
                HanziWriter javascript library
              </a>{" "}
              is used to render all characters that support it, providing the
              stroke order of the character. Characters will also have
              information about the total number of strokes, as well as the
              radical.
            </p>
          </div>
          <div className="feature">
            <h2>Radicals</h2>
            <i className="fas fa-star feature-icon"></i>
            <p>
              Every character either has a radical or is a radical. Some
              radicals will have both a simplifed and traditional variant, and
              the radicals conterpart will be displayed as a link on the
              character page if this is the case. You can filter in the search
              to show only radicals.
            </p>
          </div>
        </div>

        <div className="home-section">
          <div className="title-section">
            <h2 className="how-it-works-title red">Project Updates</h2>
            <p>
              Here you can see any updates I’ve posted about the status of this project. 
              You can also check out posts about Hanzibase on my personal website <a href="https://jsparrow.uk">through this link</a>.
            </p>
          </div>
          <Updates></Updates>
          </div>

        <div className="bottom-links-section">
          <Link className="filters-button" to="/browse">
            Browse All Characters{" "}
            <i className="filter-arrow fas fa-arrow-circle-right text-red"></i>
          </Link>
          <Link className="filters-button" to="/character/random">
            Random Character{" "}
            <i className="filter-arrow fas fa-arrow-circle-right text-red"></i>
          </Link>
        </div>
      </main>
      <Footer toggleTheme={props.toggleTheme}></Footer>
    </div>
    
    </div>
   
  );
}
