import React from "react";
import ReactDOM from "react-dom";
import "../css/styles.css";
import "../css/dark.css";

// TODO
// import "../css/light.css";
// import "../css/dark.css";
import { useState, useEffect, useLayoutEffect } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Home from "./Home";
import Single from "./Single";
import Random from "./Random";
import About from "./About";
import Chars from "./Chars";
import UpdateSingle from "./SingleUpdate";
import DEV_MODE from "../config/devMode";

import SearchCharsWrapper from "./wrappers/SearchCharsWrapper";
import StandardCharsWrapper from "./wrappers/StandardCharsWrapper";
import RadicalSearchCharsWrapper from "./wrappers/RadicalSearchCharsWrapper";

function App() {
  const [theme, setTheme] = useState("light");
  const toggleTheme = () => {
    if (DEV_MODE) {console.log('toggle dark mode')}
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const [toTop, setToTop] = useState(false);
  const toggleToTop = () => {
    setToTop(!toTop);
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  useEffect(() => {
    const mreq = window.matchMedia("(prefers-color-scheme: dark)");

    if (mreq.matches) {
      setTheme("dark");
    }

    mreq.addEventListener("change", (e) => {
      if (e.matches) {
        setTheme("dark");
      } else {
        setTheme("light");
      }
    });
  }, []);

  useLayoutEffect(() => {
    if (DEV_MODE) { console.log('setting to top') }
    window.scrollTo(0, 0);
    setToTop(false);
  }, [toTop]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Navigate to="/home" />} />
          <Route
            path="/home"
            element={
              <Home toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route
            path="/about"
            element={
              <About toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route
            path="/character/random"
            element={
              <Random toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route
            path = {decodeURIComponent("/character/:id")}
            element={
              <Single toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route
            path = {decodeURIComponent("/update/:id")}
            element={
              <UpdateSingle toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route
            path = {("/radical-search/:term")}
            element={
              <RadicalSearchCharsWrapper isSearch={true} isRadicalSearch={true} toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />    
          <Route
            path = {("/search/:term")}
            element={
              <SearchCharsWrapper isSearch={true} toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route
            path = {("/browse")}
            element={
              <StandardCharsWrapper toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />

          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
if (document.getElementById("app")) {
  ReactDOM.render(<App />, document.getElementById("app"));
}