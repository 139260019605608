import { Link } from "react-router-dom";

export default function Footer(props) {
    return (
      <>
        <hr></hr>
        <footer>
          <div className="footer-div">
            <h2>Links</h2>
            <Link to="/">Home</Link>
            <Link to="/browse">Browse</Link>
            <Link to="/about">About</Link>
            <a href="https://jsparrow.uk">HanziBase by James Sparrow</a>
          </div>
  
          <div className="footer-div">
          <button className="colourModeButton colourModeButtonFooter" onClick={props.toggleTheme}>
                <i className="fas fa-lightbulb"></i>
          </button> 

          <a href="#">Back to top</a>
          </div>
          

        </footer>
      </>
    );
  }
  