
export default function ErrorPage(props) {
    switch (props.code) {
      case 404:
        return <div className="notfound">
        <h1>很抱歉！</h1>

        <h2>Page not found</h2>

        <p>If you suspect that you're seeing this page in error, please contact the admin jamesparrow101@googlemail.com</p>
        </div>
    
      default:
        return <div className="notfound">
        <h1>很抱歉！</h1>

        <h2>{props.code}</h2>

        <p>If you suspect that you're seeing this page in error, please contact the admin jamesparrow101@googlemail.com</p>
        </div>
    }
  }
  