import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";

import ClipLoader from "react-spinners/ClipLoader";
import apiUrlBase from "../config/apiUrlBase";
import DEV_MODE from "../config/devMode";
import CharacterLink from "./partials/CharacterLink";
import Footer from "./partials/Footer";
import Navbar from "./partials/Navbar";

export default function Chars(props) {

  // abort controller
  var controller = new AbortController();
  var signal = controller.signal;

  const [sortBy, setSortBy] = useState("id");

  // filter states
  const [filterIsVisible, setFilterIsVisible] = useState(false);
  const [charsetFilter, setCharsetFilter] = useState("idc");
  const [heisigFilter, setHeisigFilter] = useState("idc");
  const [radicalFilter, setRadicalFilter] = useState(false);

  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(null);
  const [displayLoading, setDisplayLoading] = useState(false);
  const [pagesLoaded, setPagesLoaded] = useState([]);

  const [results, setResults] = useState([]);
  const [data, setData] = useState([]);
  const [currentSearchHanzi, setCurrentSearchHanzi] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // pagination state
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState();

  const observer = useRef();
  const lastCharacterRef = useCallback((node) => {
    if (!loading && !(currentPage >= lastPage)) {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setDisplayLoading(true);
          setTimeout(() => {
            setCurrentPage(currentPage + 1);
            setDisplayLoading(false);
            setLoading(true);
          }, 500);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    }
  });

  // default all chars page
  let query = apiUrlBase + "/character/filter";
  // radical
  if (props.isRadicalSearch) {
    /// this is very hack-y, should probably improve
    query = `${apiUrlBase}/character${window.location.pathname}`.replace("/radical-search/", "/search/");
  } else if (props.isSearch) {
    query = `${apiUrlBase}/character${window.location.pathname}`;
  }

  useEffect(() => {
    if (props.isRadicalSearch) {
        setSearchTerm(
            decodeURI(window.location.pathname).replace("/radical-search/", "")
          );
    }
    else if (props.isSearch) {
      setSearchTerm(
        decodeURI(window.location.pathname).replace("/search/", "")
      );
    }
    if (DEV_MODE) {console.log('search term', searchTerm)}
  }, [props.isRadicalSearch, props.isSearch, searchTerm]);

  // only reset to top on first load
  const [initToTop, setInitToTop] = useState(false);
  if (!initToTop) {
    setInitToTop(true)
    props.toggleToTop() // reset page to top
  }

  const fetchItems = async (sortUrl = "") => {
    setIsFetching(currentPage);
    if (DEV_MODE) {console.log(`${query}${sortUrl}?page=${currentPage}`)};
    await fetch(`${query}${sortUrl}?page=${currentPage}`, { signal }).then(
      async (response) => {
        //throw errors if issues
        if (response.status === 500) {
            if (DEV_MODE) {console.log("500");}
        } else if (response.status === 404) {
            if (DEV_MODE) {console.log("404");}
        } else if (response.status === 419) {
            if (DEV_MODE) {console.log("419");}
        }

        const data = await response.json();
        if (DEV_MODE) { console.log("data received: ", data); }
        if (DEV_MODE) { console.log("pagesLoaded", pagesLoaded); }

        // check if the page has already been loaded
        // we do this by checking if the page is in an array of already loaded page numbers (called pagesLoaded)
        if (pagesLoaded.indexOf(data.currentPage) !== -1) {
            if (DEV_MODE) {
            console.warn(
              `Current page is ${currentPage}, however new data input page is ${data.currentPage}. Not setting data.`
            );}
          setLoading(false);
          setIsFetching(null);
        } else {
          // the new results data
          let newResults = data.characters;

          // if the new results data has come back as an object instead of an array (happens sometimes)
          // then convert it into an array before adding to the old results
          if (typeof newResults === "object" && newResults !== null) {
            let newResultsArray = [];
            Object.keys(newResults).map((key, index) => {
              newResultsArray.push(newResults[key]);
            });
            newResults = newResultsArray;
          }

          // if there are already results
          if (results.length > 0) {
            setResults([...results, ...newResults]);
          } else {
            setResults(newResults);
          }

          //console.log(results);

          if (data.hanzi) {
            setCurrentSearchHanzi(data.hanzi);
          }

          setCurrentPage(data.currentPage);
          setPagesLoaded([...pagesLoaded, data.currentPage]); // append the current page to the list of pages loaded
          setLastPage(data.pageCount);
          setData(data);
          setLoading(false);
          setIsFetching(null);
        }
      }
    );
  };

  useEffect(() => {
    if (loading && isFetching === null) {
      let sortUrl = props.isSearch ? 
      `/${sortBy}/${heisigFilter}/${charsetFilter}/${radicalFilter ? "yes" : "idc"}/${props.isRadicalSearch ? 'yes' : 'no'}` : 
      `/${sortBy}/${heisigFilter}/${charsetFilter}/${radicalFilter ? "yes" : "idc"}`;
      fetchItems(sortUrl, currentPage);
    }
    return () => {
      controller.abort();
    };
  }, [loading]);

  // used for changing the filters or sorting
  const reset = () => {
    setCurrentPage(1);
    setPagesLoaded([]);
    setResults([]);
    setLoading(true);
  };

  //----------------------------//
  /* filter / sorting functions */
  //----------------------------//

  const changeSortBy = (e) => {
    if (isFetching !== null) {
      return;
    }
    if (e.value == "heisig_number" && heisigFilter != "yes") {
      setHeisigFilter("yes");
    }
    reset();
    setSortBy(e.value);
  };

  const changeCharsetFilter = (e) => {
    if (isFetching !== null) {
      return;
    }
    reset();
    setCharsetFilter(e.value);
  };

  const changeHeisigFilter = (e) => {
    if (isFetching !== null) {
      return;
    }
    if (e.value == "no" && sortBy == "heisig_number") {
      setSortBy("id");
    }
    reset();
    setHeisigFilter(e.value);
  };

  const radicalFilterChange = (e) => {
    if (isFetching !== null) {
      return;
    }
    reset();
    setRadicalFilter(e.target.checked);
  };

  // arrays of options for the Selects
  let sortBySelectOptions = [
    { value: "id", label: "Default" },
    { value: "updatedAt", label: "Recently Added" },
    { value: "pinyin", label: "Pinyin" },
    { value: "freq", label: "Frequency" },
    { value: "stroke_count", label: "Stroke Count" },
    { value: "heisig_number", label: "Heisig Number" },
  ];
  let charsetSelectOptions = [
    { value: "all", label: "Both" },
    { value: "simp", label: "Simplified" },
    { value: "trad", label: "Traditional" },
  ];
  let heisigSelectOptions = [
    { value: "all", label: "Include Heisig results" },
    { value: "no", label: "Exclude Heisig results" },
    { value: "yes", label: "Show only Heisig results" },
  ];

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: "#cd223d33",
      primary50: "#cd223d7d",
      primary75: "#cd223dc4",
      primary: "#cd223d",
    },
  });

  return (
    <>
      <Navbar isHomepage={false} toggleTheme={props.toggleTheme}/>

      <div className="main">
        {
            props.isRadicalSearch ? <h1>Characters with Radical {searchTerm}</h1> : searchTerm !== "" ? <h1>Results for <em>{searchTerm}</em></h1> : "" 
        }
        {!loading && <p className="total">{data.itemCount} total</p>}
        <div className="top-section">
          <button
            className="filters-button"
            value={filterIsVisible}
            onClick={() => setFilterIsVisible(!filterIsVisible)}
          >
            Filters{" "}
            {filterIsVisible ? (
              <i className="filter-arrow fas fa-arrow-circle-up"></i>
            ) : (
              <i className="filter-arrow fas fa-arrow-circle-down text-red"></i>
            )}
          </button>

          <div
            id="filters"
            className={
              "filter-section " +
              (!filterIsVisible && "filter-section-invisible")
            }
          >
            <label>
              Sorted By:
              <Select
                className="filter-select"
                value={sortBySelectOptions.find((o) => o.value === sortBy)}
                onChange={changeSortBy}
                options={sortBySelectOptions}
                theme={selectTheme}
              />
            </label>

            <label>
              Traditional/Simplified:
              <Select
                className="filter-select"
                value={charsetSelectOptions.find(
                  (o) => o.value === charsetFilter
                )}
                onChange={changeCharsetFilter}
                options={charsetSelectOptions}
                theme={selectTheme}
              />
            </label>

            <label>
              Heisig:
              <Select
                className="filter-select"
                value={heisigSelectOptions.find(
                  (o) => o.value === heisigFilter
                )}
                onChange={changeHeisigFilter}
                options={heisigSelectOptions}
                theme={selectTheme}
              />
            </label>

            {props.radical ? null : (
              <label>
                Show Only Radicals
                <input
                  className="filter-section-checkbox icon-square"
                  type="checkbox"
                  checked={radicalFilter}
                  onChange={(e) => radicalFilterChange(e)}
                />
              </label>
            )}
          </div>
        </div>

        {results.length == 0 && !loading ? (
          <div className="noResults">Sorry, no results found ;(</div>
        ) : null}

        <div className="characters_container">
          {results.map((result, i) => {
            return (
              <CharacterLink
                key={result.id}
                char={result.char}
                translations={result.translations}
                radical={result.radical}
                pinyin={result.pinyin}
                hasSimplified={result.char !== result.simp_char}
                hasTraditional={result.char !== result.trad_char}
                heisig_keyword={result.heisig_keyword}
                heisig_number={result.heisig_number}
                currentSearchHanzi={currentSearchHanzi}
                lastCharacterRef={lastCharacterRef}
                currentCharIndex={i}
                newchars={props.newchars}
                resultsLength={results.length}
              />
            );
          })}
        </div>

        {displayLoading || loading ? (
          <div className="spinner">
            <ClipLoader />
          </div>
        ) : null}

        <Footer toggleTheme={props.toggleTheme}></Footer>
      </div>
    </>
  );
}

if (document.getElementById("chars")) {
  const element = document.getElementById("chars");
  const props = Object.assign({}, element.dataset);
  ReactDOM.render(<Chars {...props} />, element);
}
