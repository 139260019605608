
import HanziWriter from "hanzi-writer";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import apiUrlBase from '../config/apiUrlBase'
import Footer from "./partials/Footer";
import Navbar from "./partials/Navbar";

import MoonLoader from "react-spinners/MoonLoader";
import ErrorPage from "./partials/ErrorPage";
import DEV_MODE from "../config/devMode";

export default function CharacterSingle(props) {
  
  const [writer, setWriter] = useState(null);
  const [context, setContext] = useState(
    {
        'character_object': {
            id: 0,
            char: "∅",
            simp_char: null,
            trad_char: "",
            freq: null,
            stroke_count: null,
            radical: null,
            simp_radical: null,
            heisig_keyword: null,
            heisig_number: null, 
            pinyin: "None",
            pinyin_normalised: "None",
            translations: "None",
            createdAt: new Date(),
            updatedAt: new Date()
        },
        'is_radical': false
    }
  );

  const [loading, setLoading] = useState(true);
  const [statusCode, setStatusCode] = useState(null);

    // only reset to top on first load
    const [initToTop, setInitToTop] = useState(false);
    if (!initToTop) {
      setInitToTop(true)
      props.toggleToTop() // reset page to top
    }

  const fetchItem = async (signal , apiUrl = `${apiUrlBase}${window.location.pathname}`) =>  {
    // console.log("load");
    // console.log(apiUrl, {signal});
    try {
        const req = await fetch(apiUrl, {
            method: "GET",
            signal: signal,
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }})
            .then(async (response) => {
                
                setStatusCode(response.status);

                const data = await response.json();
                setContext(data);
                await new Promise( res => setTimeout(res, 500))
                if (!data.category_id) {
                    setLoading(false);
                } 
        })
    }
    catch(error) {

        // console.log("OKAY I ERROR AND LOAD IS "+loading)
        // console.log("Error ", error);
    }
}

useEffect(() => {
    const controller = new AbortController();
    fetchItem(controller.signal);
  
    return () => {
      controller.abort(); 
    };
  }, []);



  const isRadical = context.is_radical;
  if (DEV_MODE) { console.log("THIS IS THE CHAR CONTEXT"); }

  let hasSimplified = context.character_object.simp_char !== context.character_object.char;
  let hasTraditional = context.character_object.trad_char !== context.character_object.char;
  const hasRadical = context.character_object.radical !== context.character_object.char;
  const hasSimplifiedRadical = context.character_object.radical !== context.character_object.simp_radical;
  if (DEV_MODE) {console.log('radical status', hasRadical, context.character_object.radical, context.character_object.char)}
  let trads = [];
  if (hasTraditional) {
    trads = context.character_object.trad_char.split(",");

    // if has same trad as char, then does not have a traditional version

    for (const trad of trads) {
      if (trad === context.character_object.char) {
        hasTraditional = false;
      }
    }
    if (DEV_MODE) { console.log("tradsArr", trads, hasTraditional); }
  }

  // if the char is the same as the simp_char, then does not have simplified version
  if (context.character_object.char == context.character_object.simp_char) {
    hasSimplified = false;
  }

  let frequencyTitle = "Unkown"; // set the frequency title
  switch (context.character_object.freq) {
    case 1:
      frequencyTitle = "Very Common";
      break;
    case 2:
      frequencyTitle = "Common";
      break;
    case 3:
      frequencyTitle = "Frequent";
      break;
    case 4:
      frequencyTitle = "Infrequent";
      break;
    case 5:
      frequencyTitle = "Very Infrequent";
      break;
    default:
      frequencyTitle = "Unknown";
      break;
  }

  let translationsArray = [];
  if (context.character_object.translations !== null) {

  translationsArray = context.character_object.translations.split(",");
  }

  //console.log(char)
  // DEBUG
  // return <div style={{"background": "white"}}>
  //         <p>Char {char.char}!</p>
  //         <p>pinyin {char.pinyin}!</p>
  //         <p>heseig num {char.heisig_number}!</p>
  //         <p>heseig word {char.heisig_keyword}!</p>
  //         <p>freq {char.freq}!</p>
  //         <p>stroke_count {char.stroke_count}!</p>
  //         <p>has_simp? {char.simp_char ? "true" : "false"}</p>
  //         <p>has_trad? {char.trad_char !== char.char ? "true" : "false"}</p>
  //         <p>has_radical? {char.radical ? "true, " + char.radical : "false"}</p>
  //         <p>has_simplifed_radical? {char.radical !== char.simp_radical ? "true, " + char.radical : "false"}</p>
  //     </div>

//   useEffect(() => {
//     if (context.character_object) {
//       console.log("SETtting new char obj from 1 to 2",char, context.char.character_object)
//       setChar(context.char.character_object);
//     }
   
//   }, [context]);

useEffect(() => {
    if (statusCode === null) {
      document.title =  `Hanzibase` ;
    } 
    else if (statusCode !== 200) {
      document.title =  `Hanzibase | ${statusCode}` ;
    }
    else {
      document.title =  `${context.character_object.char} - ${context.character_object.pinyin} | Hanzibase` ;
    }
  }, [context]);

  useEffect(() => {
    if (writer !== null) {
      writer.setCharacter(context.character_object.char);
      writer._options.strokeWidth = 1;
      writer.loopCharacterAnimation();
    }
  }, [context.character_object.char, writer]);

  useEffect(() => {
    if (writer === null && loading === false) {
      setWriter(
        HanziWriter.create("character-target-div", context.character_object.char, {
          width: 300,
          height: 300,
          padding: 15,
          strokeAnimationSpeed: 1,
          delayBetweenStrokes: 50,
          strokeColor: "#c82929",
          strokeWidth: 1,
          delayBetweenLoops: 3000,
          showOutline: true,

          // if loading the character with hanziWriter fails, just load the character as a string
          onLoadCharDataError: function (reason) {
            document.getElementById('character-target-div').innerHTML = context.character_object.char;
          },
        })
      );
    }
  }, [loading, writer, context.character_object.char]);

  return (
    
    <>
     <Navbar isHomepage={false} toggleTheme={props.toggleTheme}/>
     
     <div className="main">
     {loading && !(statusCode !== null && statusCode !== 200) ? <div className="spinner"><MoonLoader color="#c82929" size={75} speedMultiplier={.8} /></div> : (statusCode !== null && statusCode !== 200) ? <ErrorPage code={statusCode}></ErrorPage> :  <>
      {/* radical banner */}
      {isRadical ? (
        <div className="radical-header">
          <h2>Radical</h2>
            <Link to={`/radical-search/${context.character_object.char}`} className="radical-button">
              View Characters with this Radical
            </Link>

        </div>
      ) : (
        ""
      )}

      {/* Character Section */}
      <div
        className={
          "character_section" + (isRadical ? " character_section_radical" : "")
        }
      >
        {/* Left Section */}
        <div className="left_section">
          <div id="character-target-div"></div>
          <h3>{context.character_object.stroke_count} Strokes</h3>
          <div className={`freq freq-${context.character_object.freq}`}>{frequencyTitle}</div>
        </div>
        <div className="details_container">
          <div className="details_top">
            <div className="details_title">
              <h1 className="character">
                {context.character_object.char} {context.character_object.pinyin}
              </h1>
              <h1 className="character_small">{context.character_object.char}</h1>
              <h1 className="pinyin_small">{context.character_object.pinyin}</h1>
              {hasSimplified ? (
                <>
                  <span className="character_type"> (Traditional)</span>
                  <span className="character_type_small"> (Traditional)</span>
                </>
              ) : hasTraditional ? (
                <>
                  <span className="character_type_small"> (Simplified)</span>
                </>
              ) : (
                ""
              )}
            </div>

            <div className="similar_characters">
              {/* <h2>Links:</h2> */}
              {/* {{-- If the char has a radical --}} */}
              {hasRadical ? (
                <Link to={`/radical-search/${context.character_object.radical}`}>
                  <h3 className="radical-link">Radical {context.character_object.radical}</h3>
                </Link>
              ) : ''}

              {/* {{-- If the char is simplified and has a simplified radical --}} */}
              {hasSimplifiedRadical ? (
                <Link to={`/radical-search/${context.character_object.simp_radical}`}>
                  <h3 className="radical-link">Simplifed Radical {context.character_object.simp_radical}</h3>
                </Link>
              ) : ''}

              {hasSimplified ? (
                <a
                  href={`/character/${encodeURIComponent(context.character_object.simp_char)}`}
                >
                  <h3>Simplifed {context.character_object.simp_char}</h3>
                </a>
              ) : hasTraditional ? (
                <>
                  {trads.map((trad) => (
                    <a key={trad} href={`/character/${encodeURIComponent(trad)}`}>
                      <h3>Traditional {trad}</h3>
                    </a>
                  ))}
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="details_section">
            {/* Translations */}

            <div className="translations">
              <h3 className="translation_title">TRANSLATION</h3>
              {context.character_object.translations ? (
                <ul className="translation_text">
                  {translationsArray.map((translation) => (
                    <li key={translation}>{translation}</li>
                  ))}
                </ul>
              ) : (
                <p>No translations found</p>
              )}
            </div>

            <br />
            {context.character_object.heisig_keyword && context.character_object.heisig_number ? (
              <div className="heisig">
                <h3 className="heisig_title">Remebering the Hanzi:</h3>
                <p>
                  {context.character_object.heisig_keyword} : {context.character_object.heisig_number}
                </p>
              </div>
            ) : (
              ""
            )}
            <hr />
          </div>
        </div>
      </div>
      </>}
      <Footer toggleTheme={props.toggleTheme}></Footer>
      </div>
    </>
  );
}