import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import apiUrlBase from "../../config/apiUrlBase";
import DEV_MODE from "../../config/devMode";

const Updates = (props) => {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  // pagination state
  const [currentPage, setCurrentPage] = useState();
  const [lastPage, setLastPage] = useState();

  // pagination function
  const changePage = (pageToChangeTo) => {
    if (pageToChangeTo < 1 || pageToChangeTo > lastPage) {
      // console.log("Page to change to: " + pageToChangeTo + " is not within boundries");
    } else {
      setCurrentPage(pageToChangeTo);
      setLoading(true);
    }
  };

  const fetchItems = async (
    signal,
    apiUrl = `${apiUrlBase}/update?page=${currentPage}`
  ) => {
    // console.log("load");
    await fetch(apiUrl, {
      method: "GET",
      signal: signal,
      headers: {
        "Content-Type": "text/html",
        Accept: "text/html",
      },
    }).then(async (response) => {
      //throw errors if issues
      if (response.status === 500) {
        // console.log("500");
      } else if (response.status === 404) {
        // console.log("404");
      } else if (response.status === 419) {
        // console.log("419");
      }

      const data = await response.json();

      // console.log(currentPage);

      if (DEV_MODE) { console.log(data); }

      setCurrentPage(data.currentPage);
      setLastPage(data.pageCount);

      setPosts(data.posts);

      setLoading(false);
    });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchItems(signal);

    return () => {
      controller.abort();
    };
  }, [loading]);

  return (
    <div className="updates-container">
      {loading ? (
        <div className="spinner">
          <ClipLoader />
        </div>
      ) : (
        <div className="container">
          <div className="posts_container">
            {/* If this is the frist page, display the large post */}
            {currentPage === 1 ? (
              <>
                <h2 className="updates_header">Most Recent Update:</h2>
                <Link
                  key={posts[0].id}
                  to={"/update/" + posts[0].slug}
                  className="unlinkStyle"
                  onClick={props.toggleToTop}
                >
                  <div className="update_link">
                    <h3>
                      {posts[0].title} -{" "}
                      <span>
                        {new Date(posts[0].createdAt).toLocaleString("en-GB", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })}
                      </span>
                    </h3>
                    {/* Strip the body of tags and get the first 200 characters */}
                    <p>
                      {posts[0].body
                        .replace(/(<([^>]+)>)/gi, "")
                        .substring(0, 200) + "..."}
                    </p>
                  </div>
                </Link>

                <br />
                <h2 className="updates_header">Older Updates:</h2>
                <ul>
                  {posts.slice(1).map((post) => {
                    return (
                      <li key={post.id} className="small_update_link">
                        <Link
                          to={"/update/" + post.slug}
                          className="unlinkStyle"
                          onClick={props.toggleToTop}
                        >
                          {post.title} -{" "}
                          <span>
                            {new Date(post.createdAt).toLocaleString(
                              "en-GB",
                              {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                              }
                            )}
                          </span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) :
            <ul>

            <h2 className="updates_header">Older Updates:</h2>
              {posts.map((post) => {
                return (
                    <li key={post.id} className="small_update_link">
                    <Link
                      to={"/update/" + post.slug}
                      className="unlinkStyle"
                      onClick={props.toggleToTop}
                    >
                      {post.title} -{" "}
                      <span>
                        {new Date(posts[0].createdAt).toLocaleString(
                          "en-GB",
                          {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                          }
                        )}
                      </span>
                    </Link>
                  </li>
                );
              })}</ul>
            }
            <br />
            {/* if the current page isn't 1, show last page button */}
            {currentPage !== 1 ? (
              <button
                className="update-pagination-btn"
                onClick={() => {
                  changePage(currentPage - 1);
            
                }}
              >
                {"<"}- Next
              </button>
            ) : null}

            {/* if the current page isn't equal to the last page, show next page button */}
            {currentPage !== lastPage ? (
              <button
                className="update-pagination-btn"
                onClick={() => {
                  changePage(currentPage + 1);
                 
                }}
              >
                Previous -{">"}
              </button>
            ) : null}

            {/* <p>Current Page: {currentPage}</p>
                        // <p>Last Page: {lastPage}</p> */}
          </div>
        </div>
      )}

      <br />
      {currentPage > 1 ?? <p className="page-num">Page {currentPage}</p>}
    </div>
  );
};

export default Updates;
