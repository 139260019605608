
import HanziWriter from "hanzi-writer";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import {useNavigate} from 'react-router-dom'
import apiUrlBase from '../config/apiUrlBase'
import Footer from "./partials/Footer";
import Navbar from "./partials/Navbar";
import ReactHtmlParser from 'html-react-parser';

import MoonLoader from "react-spinners/MoonLoader";
import ErrorPage from "./partials/ErrorPage";
import DEV_MODE from "../config/devMode";

export default function UpdateSingle(props) {
  
  useEffect(() => {
    document.title =  "Updates | Hanzibase" ;
  });

  let navigate = useNavigate();

  const [post, setPost] = useState(null);

  const [loading, setLoading] = useState(true);
  const [statusCode, setStatusCode] = useState(null);

    // only reset to top on first load
    const [initToTop, setInitToTop] = useState(false);
    if (!initToTop) {
      setInitToTop(true)
      props.toggleToTop() // reset page to top
    }
  
  const fetchItem = async (signal , apiUrl = `${apiUrlBase}${window.location.pathname}`) =>  {
    // console.log("load");
    // console.log(apiUrl, {signal});
    try {
        const req = await fetch(apiUrl, {
            method: "GET",
            signal: signal,
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }})
            .then(async (response) => {
                
                setStatusCode(response.status);

                const data = await response.json();
                setPost(data);
                if (DEV_MODE) { console.log(data) }
                await new Promise( res => setTimeout(res, 500))
                if (!data.category_id) {
                    setLoading(false);
                } 
        })
    }
    catch(error) {

        // console.log("OKAY I ERROR AND LOAD IS "+loading)
        // console.log("Error ", error);
    }
}

useEffect(() => {
    const controller = new AbortController();
    fetchItem(controller.signal);
  
    return () => {
      controller.abort(); 
    };
  }, []);


  return (
    
    <>
     <Navbar isHomepage={false} toggleTheme={props.toggleTheme}/>
     
     <div className="main">
     {loading && !(statusCode !== null && statusCode !== 200) ? <div className="spinner"><MoonLoader color="#c82929" size={75} speedMultiplier={.8} /></div> : (statusCode !== null && statusCode !== 200) ? <ErrorPage code={statusCode}></ErrorPage> :  <>
  

      <div className="update_section">
          <div className="timestamp">Posted {new Date(post.updatedAt).toLocaleDateString()}</div>
          <br />
          <h1>{post.title}</h1>
          <br />
          {ReactHtmlParser(post.body)}
          <br /><br />
          <button 
            className="update-pagination-btn" onClick={() => {navigate(-1); props.toggleToTop()}}>
              <i className="fas fa-arrow-left"></i> Go Back
          </button>
      </div>
                
    
      </>}
      <Footer toggleTheme={props.toggleTheme}></Footer>
      </div>
    </>
  );
}