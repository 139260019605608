import { useEffect } from "react";
import Chars from "../Chars";

export default function StandardCharsWrapper(props) {
  
    useEffect(() => {
        document.title =  "Browse | Hanzibase" ;
      });
 

      return (
        <Chars {...props}></Chars>
      );
}