import { useState } from "react";
import { useEffect } from "react";
import DEV_MODE from "../../config/devMode";
import Chars from "../Chars";

export default function SearchCharsWrapper(props) {
  
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (props.isRadicalSearch) {
            setSearchTerm(
                decodeURI(window.location.pathname).replace("/radical-search/", "")
              );
        }
        else if (props.isSearch) {
          setSearchTerm(
            decodeURI(window.location.pathname).replace("/search/", "")
          );
        }
        if (DEV_MODE) {console.log('search term', searchTerm)}
      }, [props.isRadicalSearch, props.isSearch, searchTerm]);

    useEffect(() => {
        document.title =  `Search for '${searchTerm}' | Hanzibase` ;
      });
 

  return (
    <Chars {...props}></Chars>
  );
}