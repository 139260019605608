import { useEffect } from "react";
import { useState } from "react";
import Footer from "./partials/Footer";
import Navbar from "./partials/Navbar";

export default function About(props) {
  
    useEffect(() => {
        document.title =  "About | Hanzibase" ;
      });
 
  // only reset to top on first load
  const [initToTop, setInitToTop] = useState(false);
  if (!initToTop) {
    setInitToTop(true)
    props.toggleToTop() // reset page to top
  }

  return (
    <>
    
     <Navbar isHomepage={false} toggleTheme={props.toggleTheme} />
     <div className="main">
      
     <h1 style={{'padding': '1em 0'}}>About</h1>
<p>HanziBase is a website database of Chinese characters, created to help me with learning them. If you have any questions or comments, you can contact me at jamessparrow101@googlemail.com</p>


     
    
      <Footer toggleTheme={props.toggleTheme}></Footer>
      </div>
    </>
  );
}