import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function Navbar(props) {

  const [isHomepage, setIsHomepage] = useState(props.isHomepage);

  const [ displayStyle, setDisplayStyle ] = useState({});
  const [ searchDisplayStyle, setSearchDisplayStyle ] = useState({});
// When the user scrolls down 80px from the top of the document, resize the navbar's padding and the logo's font size

// search state / handle function
const [search, setSearch] = useState("");
const handleSearchChange = (e) => {
  setSearch(e.target.value);
}

function burgerMenuShowHide() {

  var button = document.getElementById("bMenuButton");
  var bMenu = document.getElementById("bMenu");

  // open
  if(bMenu.className === "bMenu bMenuClosed") {
      bMenu.className = "bMenu";
      
      button.innerHTML = '<i class="fas fa-times"></i>';
      console.log("open");
  }

  // close
  else {
      bMenu.className = "bMenu bMenuClosed";

      button.innerHTML = '<i class="fas fa-bars"></i>';
      console.log("close");
  }
}

const handleScroll = () => {
  if (window.scrollY > 580) {
    setDisplayStyle({'background': '#3f3f3f', 'borderBottom': '2px solid #2e131394'});
    setSearchDisplayStyle({'display': 'initial'})
  }
  else {
    setDisplayStyle({'background': 'none', 'borderBottom': 'none'})
    setSearchDisplayStyle({'display': 'none'})
  }
};


useEffect(() => {
  setIsHomepage(props.isHomepage);
  
}, [props.isHomepage])


useEffect(() => {
  if (isHomepage) {
    window.addEventListener("scroll", handleScroll, { passive: true }); 
    // return () => window.removeEventListener("scroll", handleScroll);
  }
  else {
    setDisplayStyle({'background': '#3f3f3f', 'borderBottom': '2px solid #2e131394'});
    setSearchDisplayStyle({'display': 'initial'});
  }
}, [isHomepage]);

  return (
    <>
      <div className="topbar" style={displayStyle}>
        <Link className="logo-container-top" to="/">
            <img src="/icon.webp" alt="hanzibase" height={80} width={80}></img>
        </Link>
        <div className="search-container-top" style={searchDisplayStyle}>
          <form action={`/search/${search}`} method="GET">
            <input
              id="search"
              type="text"
              placeholder="Search.."
              onChange={(e) => handleSearchChange(e)}
              autoComplete="off"
              maxLength={32}
              required
            ></input>
            <label style={{ display: "none" }} htmlFor="search">
              Search field
            </label>
            <button aria-label="Search Button" type="submit">
              <i className="fa fa-search"></i>
            </button>
          </form>
        </div>

        <div className="links-container-top">
          <Link to="/home#">Home</Link>
          <Link to="/browse#">Browse</Link>
          <Link to="/character/random#">Random</Link>
          <button className="colourModeButton" onClick={props.toggleTheme}>
                <i className="fas fa-lightbulb"></i>
        </button>  
        </div>
        <button
          aria-label="Menu Button"
          onClick={burgerMenuShowHide}
          id="bMenuButton"
          className="hamburger-button"
        >
          <i className="fas fa-bars"></i>
        </button>
      </div>
      <div className="topbar-clear"></div>

      {/* {{-- The burger menu for mobile --}} */}
      <div id="bMenu" className="bMenu bMenuClosed">
        <div className="bMenu-container">
          <Link to="/">Home</Link>
          <Link to="/browse">Browse</Link>
          <Link to="/character/random">Random</Link>

          <div className="bMenu-bottom-link">
            <a href="https://jsparrow.uk">HanziBase by James Sparrow</a>
          </div>
        </div>
      </div>
    </>
  );
}